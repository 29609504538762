import { apiPcNotifyList } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            dialogVisibleDownloadApp: false,
            resultData: [],
            downloadData: [
                {
                    type: this.$t('m.wechatdownload'),
                    url: require('@/assets/temporary/download.png')
                }
                /*
                {
                    type: 'IOS端',
                    url: require('@/assets/temporary/androidQcode.jpg')
                },
                {
                    type: 'Android端',
                    url: require('@/assets/temporary/androidQcode.jpg')
                }*/
            ],
            page: 1,
            loading: false,
            noMore: false,
            noData: false,
            frist: true,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getNotifyList() 
        },
        getNotifyList() {
            //if (this.noMore || this.loading) return
			if (this.noMore) return
            this.loading = true
            let paramsBase = {
                cate: 1,
                page: this.page
            }
            apiPcNotifyList(paramsBase).then((res) => {
                if (res.data.length == 0 && this.frist) {
                    this.frist = false;
                }
                let dataValue = res.data.list
                //if (this.page <= res.data.count_page) {
				if (dataValue.length !== 0) {
                    setTimeout(() => {
                        dataValue.forEach(element => {
                            this.resultData.push(element)
                        });
                    }, 500);
                } else {
                    this.loading = false
                    this.noMore = true
                    
                }
                
                if(res.data.length==0){
                    this.noData = true;
                }
            })
        },
        clickSeeDetails() {
            this.dialogVisibleDownloadApp = true
        },
        load() {
            this.page++
            this.getNotifyList()
        }
    },
}